<template>
    <div>
        <b-card title="Create Company">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row></b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Name" rules="required">
                                <b-form-group label="Name" label-for="input-default">
                                    <b-form-input id="input-default" v-model="body.Name" :plaintext="togglePlaintext"
                                        :state="errors.length > 0 ? false : null" />
                                </b-form-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Description" rules="required">
                                <b-form-group label="Description" label-for="input-default">
                                    <b-form-input id="input-default" v-model="body.Description" :plaintext="togglePlaintext"
                                        :state="errors.length > 0 ? false : null" />
                                </b-form-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-row></b-row>
            <b-row>
                <b-col></b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                    :hidden="hiddenComponent">
                    <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Create Company
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/companies">
                    <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Go Back
                </b-button>
                <b-col></b-col>
            </b-row>
        </b-card>
    </div>
</template>
  
<script>
import { BCard, BCardText, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BRow, BCol } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import { decryptString } from '@/libs/crypto'

export default {
    components: {
        BCard, BCardText, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BRow, BCol, ValidationProvider, ValidationObserver,
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.message();
                }
            })
        },
        async message() {
            const error = await this.onSubmit();
            if (error[0]) {
                const variant = "danger";
                this.$bvToast.toast("" + error[1], {
                    title: "Failed",
                    variant,
                    solid: true,
                });
            } else if (!error[0]) {
                this.togglePlaintext = true;
                this.disable = true;
                this.hiddenComponent = true;
                const variant = "success";
                this.$bvToast.toast("Company created correctly", {
                    title: `Create`,
                    variant,
                    solid: true,
                });
            }
        },
    },
    data() {
        return {
            required,
            disable: false,
            hiddenComponent: false,
            togglePlaintext: false,
        };
    },
    setup() {
        const blankCompanyData = {
            Name: "",
            Description: "",
            IdUser: decryptString(localStorage.getItem('idUser')),
        };

        const body = ref(JSON.parse(JSON.stringify(blankCompanyData)));

        const onSubmit = async () => {
            const onError = [false, ""];
            await axios
                .post("/company", body.value)
                .then((result) => {
                    onError[0] = false;
                })
                .catch((error) => {
                    onError[0] = true;
                    onError[1] = error;
                });
            return onError;
        };

        return {
            body,
            onSubmit,
        };
    },
    mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.row {
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}
</style>
  